<template>
  <div>
    <b-modal
      id="modal-product-catalog"
      centered
      scrollable
      hide-footer
      size="xl"
    >
      <template #modal-header>
        <h5 class="modal-title">{{ product?.name }}</h5>
        <button class="btn-close-product-catalog" @click="$bvModal.hide('modal-product-catalog')">&times;</button>
      </template>
      <div class="row">
        <div class="col-lg-12 text-center my-5" v-if="isLoading">
          <font-awesome icon="spinner" size="6x" spin />
        </div>
        <template v-else-if="product">
          <div class="col-lg-5 col-12 product-catalog-images mb-3 mb-lg-0">
            <VueSlickCarousel
              ref="productCatalogImages"
              v-bind="productImageSlickSettings"
              @beforeChange="syncSliders"
            >
              <b-img
                v-for="(productImage, index) in product.product_images"
                :key="index"
                :src="productImage.raw_image_url || productImage.full_image_url"
                :alt="`${index + 1} ${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`"
                class="product-catalog-img mb-2"
                @error="replaceByDefaultImage"
              />
            </VueSlickCarousel>
            <VueSlickCarousel
              ref="productCatalogThumbnail"
              v-bind="productThumbnailSlickSettings"
              class="product-catalog-thumbnail-slider"
              @beforeChange="syncSliders"
            >
              <b-img
                v-for="(productThumbnail, index) in product.product_images"
                :key="index"
                :src="productThumbnail.thumbnail_image_url"
                :alt="`Thumbnail ${index + 1} ${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`"
                @error="replaceByDefaultImage"
                class="product-catalog-thumbnail"
              />
            </VueSlickCarousel>
          </div>
          <div class="col-lg-7 col-12 product-catalog-info">
            <div class="bold">{{ product.brand_name }}</div>
            <div class="product-name mb-2">{{ product.name }}</div>
            <template v-if="product.status_purchaseable">
              <!-- Product Rating -->
              <div v-if="product.rating.average > 0" class="text-warning mb-2">
                <div class="d-inline-block border-right pr-2">
                  <fawesome-pro
                      v-for="i in 5" :key="i"
                      :variant="product.rating.average - i > -1 ? 'fas' : 'far'"
                      :icon="product.rating.average - i > -1 && product.rating.average - i < 0 ? 'star-half-alt' : 'star'"
                      class="pr-1"
                    />
                    {{ (Math.round(product.rating.average * 10) / 10).toFixed(1) }}
                </div>
                <span class="pl-1">
                  {{ 
                    product.rating.average == 5
                    ? "Outstanding" : product.rating.average >= 4
                    ? "Lovely" : product.rating.average >= 3
                    ? "Pretty good" : product.rating.average >= 2
                    ? "Could be better" : "Not satisfying" 
                  }}
                </span>
              </div>
              <!-- Product Stock -->
              <div id="product-stock" class="d-inline-block mb-3">
                <div
                  class="product-status-badge"
                  :class="[
                    variantStock <= 0
                      ? 'outstock-badge' // Out of Stock Product
                      : !product.status_stock
                      ? 'preorder-badge' // Pre Order Product
                      : 'instock-badge', // In Stock Product
                  ]"
                >
                  <template v-if="variantStock <= 0">Sold Out</template>
                  <template v-else-if="!product.status_stock">Pre-order</template>
                  <template v-else>In Stock</template>
                </div>
                <template v-if="variantStock > 0">
                  <small class="ml-1 bold">
                    {{
                      !product.status_stock
                        ? `${product.ready_stock_duration} Day(s)`
                        : `Available: ${variantStock}`
                    }}
                  </small>
                </template>
              </div>
              <!-- Product Batch PO -->
              <div class="mb-3" v-if="product.batch_po">
                <!-- PO Batch Before Start -->
                <div
                  class="d-flex align-items-center"
                  v-if="currentTime < product.start_date_po"
                >
                  <div class="mr-1">Starts in</div>
                  <div class="product-status-badge instock-badge d-flex align-items-center">
                    <font-awesome icon="clock" class="mr-1"/>
                    <div>
                      <b>{{ countdownDuration("days") }}</b> D
                      <b>{{ countdownDuration("hours") }}</b> H
                      <b>{{ countdownDuration("minutes") }}</b> M
                    </div>
                  </div>
                </div>
                <!-- PO Batch End -->
                <div
                  class="product-status-badge outstock-badge d-flex align-items-center"
                  v-else-if="currentTime > product.end_date_po"
                >
                  <font-awesome icon="clock" class="mr-1" />
                  <div>Closed order</div>
                </div>
                <!-- PO Batch Active -->
                <div class="d-flex align-items-center" v-else>
                  <div class="mr-1">Ends in</div>
                  <div class="product-status-badge unavailable-badge d-flex align-items-center">
                    <font-awesome icon="clock" class="mr-1"/>
                    <div>
                      <b>{{ countdownDuration("days") }}</b> D
                      <b>{{ countdownDuration("hours") }}</b> H
                      <b>{{ countdownDuration("minutes") }}</b> M
                    </div>
                  </div> 
                </div>
              </div>
              <div class="row mb-3">
                <!-- Product Price -->
                <div class="col-6 d-flex align-items-center">
                  <template v-if="product.checkout_type == 1">{{ $helpers.format.price(product.price_display) }} Liz-Points</template>
                  <template v-else>
                    <fawesome-pro variant="fal" icon="tag" class="pr-1" title="Price" />
                    <div class="product-catalog-price pl-2">
                      <b :class="{'text-gold' : (product.product_type == 4 && product.discount_active_bundle) || product.discount_active || product.bundle_dynamic_price }">{{ product.bundle_dynamic_price ? $helpers.product.formatBundlePrice(product) : $helpers.format.price(product.price_display) }}</b><br/>
                      <strike v-if="product.product_type == 4 && product.discount_active_bundle">{{ $helpers.product.formatBundlePriceOrigin(product) }}</strike>
                      <strike v-else-if="product.discount_active">{{ $helpers.format.price(product.price) }}</strike>
                      <span v-if="product.discount_badge" class="badge badge-beige ml-1">{{ product.discount_badge }}</span>
                    </div>
                  </template>
                </div>
                <!-- Installment (If product category is not fine gold / gift / bundle)-->
                <div class="col-6 d-flex align-items-center" v-if="!/fine|gift|bundle/i.test(productCategory)">
                  <fawesome-pro variant="fal" icon="credit-card" class="pr-1" title="Installment" />
                  <div class="product-catalog-installment pl-2">{{ $helpers.format.price((product.price_display / 12)) }} &times; 12</div>
                </div>
                
              </div>
              <template v-if="product.product_bundle">
                <hr />
                <p class="m-0">Choose {{product.product_bundle.length}} product(s)<br/>
                <template v-if="product.product_bundle.filter(el => el.submitted).length > 0">
                  <a class="roboto-bold custom-font-24px" :class="{ 'text-black': !product.discount_active_bundle }">
                    {{ $helpers.format.price(bundleTotalPriceDisplay) }}
                    <span class="old-price" v-if="product.discount_active_bundle && bundleTotalPriceDisplay < bundleTotalPrice">{{ (total => total > 0 ? $helpers.format.price(total) : '')(bundleTotalPrice) }}</span>
                  </a>
                  <small class="text-success" v-if="product.discount_active_bundle && bundleTotalPriceDisplay < bundleTotalPrice">{{ (saved => saved > 0 ? `Save ${$helpers.format.price(saved)}` : '')(bundleSaveAmount) }}</small>
                </template>
                </p>
                <div class="row no-gutters row-cols-2 row-cols-lg-3 bundling-prod-container overflow-x-styled">
                  <div class="col p-1" v-for="(bundle, i) in product.product_bundle" :key="i">
                    <div class="bundling-prod-card">
                      <div v-if="!bundle.submitted" class="bundling-prod-box" title="Select a product"  @click="productToPass = product; setBundleChoiceIndex(product.product_bundle, bundle, i)" v-b-modal.modal-add-to-bundle></div>
                      <!-- <div v-if="!bundle.submitted" class="bundling-prod-box" title="Select a product" @click="bundlingIndex = i; productToPass = product" v-b-modal.modal-add-to-bundle></div> -->
                      <div v-else class="bundling-prod-body">
                        <div class="bundling-prod-img">
                          <b-img-lazy fluid :src="bundle.submitted.product_images[0].medium_image_url"
                            :title="bundle.submitted.name"
                            :alt="bundle.submitted.name"
                            @click.native="bundlingIndex = i; productToPass = product"
                            @error.native="replaceByDefaultImage"
                            v-b-modal.modal-add-to-bundle
                          />
                          <router-link class="bundling-prod-view" :to="`/product/${bundle.submitted.slug}`" target="_blank">
                            <img src="@/assets/img/zoom-product.svg" alt="" title="Go to product page" width="24" />
                          </router-link>
                        </div>
                        <div class="bundling-prod-info p-2">
                          <label class="d-block">
                            <b>{{bundle.submitted.brand_name}}</b><br/>
                            {{bundle.submitted.name}}
                          </label>
                          <div class="text-center">
                            <a class="d-block" :class="bundle.submitted.price_display < bundle.submitted.price ? 'text-gold' : 'text-black'">
                              <span class="roboto-bold">{{ $helpers.format.price(bundle.submitted.price_display) }}</span>  
                              <span v-if="bundle.submitted.price_display < bundle.submitted.price" class="old-price custom-font-11px mt-1">{{ $helpers.format.price(bundle.submitted.price) }}</span> 
                            </a>
                            <small class="text-grey d-inline-block mt-2" v-if="bundle.submitted.category_name.indexOf('Fine') < 0">
                              Color: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)(bundle.submitted.product_detail_variance.find(el => el.id == bundle.submitted.selectedVariant).color.name) }}</b><br/>
                              <template v-if="detectRingSizes(bundle.submitted)">
                                Size: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)(bundle.submitted.product_detail_variance.find(el => el.id == bundle.submitted.selectedVariant).size.international) }}</b>
                              </template>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Product Color & Size -->
              <div v-else-if="!/fine|gift|bundle/i.test(productCategory) && product.product_detail_variance.length" class="row no-gutters mb-3">
                <div class="col-2 font-14px align-self-center mb-1">Color</div>
                <div class="col-10 mb-1">
                  <label class="radio-badge" v-for="(color, i) in groupProductColors(product)" :key="i">
                    <input type="radio" v-model="selectedColor" :value="color.id" hidden @input="chooseColor($event.target.value)">
                    <div class="badge" :style="{'--background': color.color}">{{ color.name[0] == '-' ? '-' : color.name }}</div>
                  </label>
                </div>
                <template v-if="selectedColor && productCategory == 'Ring'">
                  <div class="col-2 font-14px align-self-center text-nowrap">
                    Size <fawesome-pro variant="fal" icon="info-circle" class="category-size-info" v-b-modal.modal-category-size v-if="product.category_size.length" @click.native="$emit('showModalCategorySize', product)" />
                  </div>
                  <div class="col-10">
                    <label class="radio-badge" v-for="(variant, i) in product.product_detail_variance.filter(el => el.color.id == selectedColor)" :key="i">
                      <input type="radio" v-model="selectedSize" :value="variant.size.id" hidden :disabled="variant.total_stock == 0" @input="chooseSize($event.target.value)">
                      <div class="badge">{{ variant.size.international }}</div>
                    </label>
                  </div>
                </template>
              </div>

              <div class="row mb-3">
                <div class="col-2 font-14px align-self-center mb-1">Quantity</div>
                <div class="col-4 mb-1">
                  <MyInputFormQuantity
                    v-model="productQuantity"
                    @onClickMinus="reduceQuantity"
                    @onClickPlus="addQuantity"
                    min="1"
                    :max="variantStock"
                  />
                </div>
              </div>

              <b-button
                type="button"
                class="btn-primary mb-3"
                block
                @click="orderViaWhatsapp"
                :disabled="product.product_type == 4 && (submittedBundleProduct.length < product?.product_bundle?.length)"
              >
                Order Via Whatsapp
              </b-button>
              <!-- Product Specification -->
              <div class="product-spec-title mb-2">PRODUCT SPECIFICATION</div>
              <div class="product-spec row mb-3">
                <div class="col-6">Dimension <span class="ml-auto">{{ product.dimension ? product.dimension : '-' }}</span></div>
                <div class="col-6">Total Ct (gr) <span class="ml-auto">{{ product.sum_carat_of_gold ? product.sum_carat_of_gold : '-' }}</span></div>
                <div class="col-6">Model <span class="ml-auto">{{ product.category3_name ? product.category3_name : '-' }}</span></div>
                <div class="col-6">Emas + Diamond (gr) <span class="ml-auto">{{ product.weight_of_gold_plus_diamond ? product.weight_of_gold_plus_diamond : '-' }}</span></div>
                <div class="col-6">Kadar <span class="ml-auto">{{ product.kadar ? product.kadar : '-' }}</span></div>
                <div class="col-6">Approximate Weight (gr) <span class="ml-auto">{{ product.weight ? product.weight : '-' }}</span></div>
              </div>
              <!-- Product Description -->
              <template v-if="product.description">
                <div class="product-desc-title mb-2">PRODUCT DESCRIPTION</div>
                <div class="product-desc" v-html="product.description"></div>
              </template>
            </template>
          </div>
        </template>
      </div>
    </b-modal>
    <ModalAddBundle :index="bundlingIndex" :parent="productToPass" :isOrderViaWhatsapp="true" @nextIndex="bundlingIndex += 1" @previousIndex="bundlingIndex -= 1" @showModalCategorySize="$emit('showModalCategorySize', $event)" @orderViaWhatsapp="orderViaWhatsapp" />
  </div>
</template>

<script>
import moment from "moment";
const ModalAddBundle = () => import("@/components/modals/ModalAddBundle");
const MyInputFormQuantity = () => import("@/components/form/MyInputFormQuantity");

import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ModalProductCatalog",
  components: {
    ModalAddBundle,
    MyInputFormQuantity,
    VueSlickCarousel,
  },
  props: ["product", "resellerContact", "isLoading"],
  data() {
    return {
      variantStock: 0,
      productCategory: null,
      selectedColor: null,
      selectedSize: null,
  
      bundlingIndex: null,
      productToPass: null,
  
      productQuantity: 1,

      // Vue slick carousel settings
      productImageSlickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: this.$refs.productCatalogThumbnail,
      },
      productThumbnailSlickSettings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: this.$refs.productCatalogImages,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              vertical: false,
              verticalSwiping: false,
              arrows: false,
            },
          },
        ],
      },
    }
  },
  computed: {
    //? Get total price display (discounted product price) from selected product from each bundle option
    bundleTotalPriceDisplay() {
      return this.product.bundle_dynamic_price ? this.calculateSelectedBundleProduct() - this.discountAmount : this.product.price_display;
    },
    //? Get total price (original product price) from selected product from each bundle option
    bundleTotalPrice() {
      return this.calculateSelectedBundleProduct(false);
    },
    //? Get save amount if discount is active for bundle product
    bundleSaveAmount() {
      return this.bundleTotalPrice - this.bundleTotalPriceDisplay;
    },
    //? Calculate discount amount for bundle product
    discountAmount: function() {
      const product = this.product;
      if ((!product.discount_active_bundle || !product.discount) || !product.bundle_dynamic_price) return 0;

      let discount = 0;

      if (product.discount_type == 1) discount = product.discount ?? 0;
      else if (product.discount_type == 2) discount = this.calculateSelectedBundleProduct() * (product.discount / 100) ?? 0;

      return discount;
    },
    //? Submitted bundle product
    submittedBundleProduct() {
      return this.product?.product_bundle?.filter(el => el.submitted) || [];
    },
  },
  watch: {
    product() {
      this.setProduct();
      this.productQuantity = 1;
    },

    selectedColor() {
      if (this.productQuantity <= this.variantStock) return
      this.productQuantity = 1;
      this.$helpers.toaster.make("Product quantity exceeds the stock, please set again the quantity", "warning");
    },
    selectedSize() {
      if (this.productQuantity <= this.variantStock) return
      this.productQuantity = 1;
      this.$helpers.toaster.make("Product quantity exceeds the stock, please set again the quantity", "warning");
    }
  },
  methods: {
    setProduct() {
      // Set product category
      this.productCategory = this.product.product_detail_variance.map(el => el.size).filter((v, i, a) => a.findIndex(el => el.id == v.id && el.international[0] != '-') === i).length > 0
        ? 'Ring' : this.product.category3_name

      // if product have batch PO, set current time to update countdown duration per seconds
      if (this.product.batch_po) {
        this.setCurrentTime();
        setInterval(this.setCurrentTime.bind(this), 1000);
      }

      if (this.product.product_type == 4 && this.product?.product_bundle?.length) this.productToPass = this.product;


      if (!/fine|gift|bundle/i.test(this.productCategory) && this.product.product_detail_variance.length > 0) this.autoPickVariant();

      else this.variantStock = this.product.total_stock;
    },

    autoPickVariant() {
      const availableVariant = this.product.product_detail_variance.find(el => el.total_stock > 0)
      if (!availableVariant) return;
      this.selectedColor = availableVariant.color.id
      this.selectedSize = availableVariant.size.id

      this.variantStock = this.product.product_detail_variance.find(el => el.color.id == this.selectedColor && el.size.id == this.selectedSize).total_stock
    },

    chooseColor(colorId) {
      this.selectedSize = null

      this.variantStock = this.product.product_detail_variance
        .filter((el) => el.color.id == colorId)
        .reduce((total, el) => total + el.total_stock, 0);
    },

    chooseSize(sizeId) {
      this.variantStock = this.product.product_detail_variance
        .find((el) => el.color.id == this.selectedColor && el.size.id == sizeId).total_stock
    },

    groupProductColors(product) {
      // https://stackoverflow.com/a/54088854
      return [...product.product_detail_variance.map(el => el.color).reduce((a, b) => {
        a.set(b.id, b)
        return a
      }, new Map()).values()]
    },

    orderViaWhatsapp() {
      let text = "";
      const productCatalogLink = process.env.VUE_APP_FE_URL + this.$router.currentRoute.path.substring(1) + `/${this.product.slug}` + `?p=${this.$route.query.p || 1}`;
      if (this.product.product_type == 4) {
        text = `Hai saya mau order bundle *${this.product.name}*\nProduk yang saya pilih:`;
        for (const index in this.submittedBundleProduct) {
          const el = this.submittedBundleProduct[index];
          const colorProductBundle = el.submitted.product_detail_variance.find(rel => rel.id == el.submitted.selectedVariant).color.name;
          const sizeProductBundle = el.submitted.product_detail_variance.find(rel => rel.id == el.submitted.selectedVariant).size.international;
          text += `\n${parseInt(index) + 1}. ${el.submitted.name}` + ' (' + (colorProductBundle ? `Color: ${colorProductBundle}` : '') + (sizeProductBundle ? `, Size: ${sizeProductBundle}` : '') + ')';
        }
      } else {
        const colorFound = this.product.product_detail_variance.find(el => el?.color.id == this.selectedColor)?.color.name;
        const sizeFound = this.product.product_detail_variance.find(el => el?.size.id == this.selectedSize)?.size.international;
        text = `Hai, saya mau order barang *${this.product.name}*` + (colorFound ? `\nColor : ${colorFound}` : "") + (sizeFound ? `\nSize : ${sizeFound}` : "");
      }
      text += `\nQuantity : ${this.productQuantity} \nLink : ${productCatalogLink}`;
      text = encodeURIComponent(text);
      window.open(`https://api.whatsapp.com/send?phone=${this.resellerContact.phone}&text=${text}`)
    },    

    syncSliders(currentPosition, nextPosition) {
      this.$refs.productCatalogThumbnail.goTo(nextPosition);
      this.$refs.productCatalogImages.goTo(nextPosition);
    },

    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
      img.target.classList.add("default-image");
    },

    // Product PO Batch Method(s)
    countdownDuration(time) {
      const duration = moment.duration(this.epochBatchPoCountdown, "milliseconds");
      // if days/hours/minutes < 10 add leading zeros
      const daysLeft = duration.asDays() >= 10 ? parseInt(duration.asDays()) : "0" + parseInt(duration.asDays()).toString();
      const hoursLeft = duration.hours() >= 10 ? duration.hours() : "0" + duration.hours().toString();
      const minutesLeft = duration.minutes() >= 10 ? duration.minutes() : "0" + duration.minutes().toString();
      if(time == 'days') return daysLeft
      if(time == 'hours') return hoursLeft
      if(time == 'minutes') return minutesLeft
    },
    setCurrentTime() {
      this.currentTime = Math.round(Date.now());
    },

    // Handler for opening which bundle choice index between the submitted choice(use index) or not yet submitted choice(use notSubmittedLastIndex) 
    setBundleChoiceIndex(bundle, choices, index) {
      const notSubmittedLastIndex = bundle.findIndex(el => !el.submitted);
      this.bundlingIndex = choices.submitted ? index : notSubmittedLastIndex;
    },

    calculateSelectedBundleProduct(isPriceDisplay = true) {
      return this.product?.product_bundle?.filter(el => el.submitted).reduce((sumBundlePrice, { bundle_product }) => sumBundlePrice + bundle_product.reduce((sumProductPrice, { price, price_display, status_purchaseable, selected }) => sumProductPrice + (status_purchaseable && selected ? isPriceDisplay ? price_display : price : 0), 0), 0)
    },
    detectRingSizes(product) {
      return product.product_detail_variance
        .reduce((arr, el) => {
          const { international } = el?.size
          if (international) arr.push(international)
          return arr
        }, [])
    },
    addQuantity() {
      if (this.productQuantity < this.variantStock) this.productQuantity++;
    },
    reduceQuantity() {
      if (this.productQuantity - 1 > 0) this.productQuantity-- }
    },
};
</script>
<style scoped>
/* TODO Remove if css selector (.modal .close) is deleted */
.btn-close-product-catalog {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  padding: 1.2rem;
  margin: -1rem -1rem -1rem auto; 
}
.product-catalog-info .product-name {
  font-size: 1.5rem;
}
.product-catalog-price {
  line-height: 1rem;
}
.radio-badge .badge {
  border: 1px solid transparent;
  background: #ebebeb;
  font-weight: normal;
  margin: 3px;
  cursor: pointer;
}
.radio-badge .badge:hover {
  background: #ccc;
}
label.radio-badge {
  font-size: initial;
  margin-bottom: initial;
  cursor: pointer;
}
label.radio-badge input[type="radio"]:disabled + .badge {
  color: rgba(0, 0, 0, 0.23);
  cursor: not-allowed;
}
label.radio-badge input[type="radio"]:checked + .badge {
  border-color: #000;
  background: var(--background, #fff);
}
.product-spec-title, .product-desc-title {
  letter-spacing: 0.7px;
  font-weight: bold;
}
.product-spec div[class*="col-"] {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: .25rem;
  color: #454545;
}
.product-desc {
  max-height: 220px;
  overflow-y: scroll;
  scrollbar-width: thin;
  color: #454545;
}
.product-desc::-webkit-scrollbar {
	width: 4px;
}
.product-desc::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.product-desc::-webkit-scrollbar-thumb {
	background-color: #eee;
}
.product-catalog-img {
  border-radius: 8px;
}
.product-catalog-thumbnail {
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .product-catalog-price,
  .product-catalog-installment,
  .product-spec div[class*="col-"] {
    font-size: 12px;
  }
}
</style>